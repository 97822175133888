<template>
  <div class="PauseTimeProjectForm-wrapper">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
          <el-form-item v-if="isPlatform" label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
            <v-select2 v-model="form.regionId" placeholder="输入公司名称" v-bind="regionParams" :width="width" />
          </el-form-item>
          <el-form-item v-if="isPlatform || isRegion" label="所属项目" prop="communityId" :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]">
            <v-select2 ref="communityRef" v-model="form.communityId" placeholder="输入项目名称" v-bind="communityParams" :subjoin="communitySubjoin" :width="width" />
          </el-form-item>
          <el-form-item label="选择应用" prop="subId" :rules="[{ required: true, message: '请选择应用', trigger: 'change' }]">
            <v-select2 ref="appRef" v-model="form.subId" placeholder="输入应用名称" v-bind="subParams" :subjoin="subjoin"  :width="width" />
          </el-form-item>
          <el-form-item label="暂停操作" :rules="[{ required: true, message: '请选择暂停操作', trigger: 'change' }]" prop="pauseType">
            <v-select v-model="form.pauseType" :options="pauseTypeOps" :width="width" />
          </el-form-item>
          <el-form-item v-if="timeVisible" label="暂停时间" prop="time" :rules="timeRules">
            <v-datepicker :startTime.sync="form.time.pauseFrom" :endTime.sync="form.time.pauseTo" type="rangedate"></v-datepicker>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import { regionParams, communityParams } from 'common/select2Params'
import { getApplicationURL } from './api'
import { pauseTimeURL } from '../api'
import { setPauseTypeOps } from '../map'

export default {
  name: 'PauseTimeProjectForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      pauseTypeOps: setPauseTypeOps(),
      regionParams,
      communityParams,
      subParams: {
        searchUrl: getApplicationURL,
        request: {
          text: 'subName',
          value: 'id'
        }
      },
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { pauseFrom, pauseTo } = value
              if (!pauseFrom) {
                callback(new Error(`请选择开始时间`))
              } else if (!pauseTo) {
                callback(new Error(`请选择结束时间`))
              } else {
                callback()
              }
            } else {
              callback()
            }
          }
        },
        {
          required: true
        }
      ],
      form: {
        id: undefined,
        regionId: undefined,
        communityId: undefined,
        subId: undefined,
        pauseType: undefined,
        time: {
          pauseFrom: '',
          pauseTo: ''
        }
      },
      submitConfig: {
        submitUrl: pauseTimeURL,
        submitMethod: 'PUT'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置应用暂停时间')
    this.form.pauseType = this.pauseTypeOps[0].value
  },
  computed: {
    timeVisible () {
      return this.form.pauseType === 1
    },
    isPlatform () {
      let userType = this.$store.state.userInfo.userType
      return ['106', '100'].includes(userType)
    },
    isRegion () {
      return this.$store.state.userInfo.userType === '102'
    },
    communitySubjoin () {
      return {
        regionId: this.form.regionId,
        isNormal: 1
      }
    },
    subjoin () {
      return {
        communityId: this.form.communityId
      }
    }
  },
  methods: {
    submitBefore (data) {
      if (this.timeVisible) {
        data['pauseFrom'] = data['time'].pauseFrom
        data['pauseTo'] = data['time'].pauseTo
      }
      delete data['time']

      if (this.$refs.communityRef && this.$refs.appRef) {
        let dataObject = `${this.$refs.appRef.getBackTextValue()}-${this.$refs.communityRef.getBackTextValue()}`
        data.dataObject = dataObject
      }

      return true
    }
  }
}
</script>
