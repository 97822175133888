var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PauseTimeProjectForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          { attrs: { form: _vm.form, submitBefore: _vm.submitBefore } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _vm.isPlatform
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属公司",
                            prop: "regionId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属公司",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "输入公司名称",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.regionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "regionId", $$v)
                                  },
                                  expression: "form.regionId",
                                },
                              },
                              "v-select2",
                              _vm.regionParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPlatform || _vm.isRegion
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属项目",
                            prop: "communityId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属项目",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "communityRef",
                                attrs: {
                                  placeholder: "输入项目名称",
                                  subjoin: _vm.communitySubjoin,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择应用",
                        prop: "subId",
                        rules: [
                          {
                            required: true,
                            message: "请选择应用",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "appRef",
                            attrs: {
                              placeholder: "输入应用名称",
                              subjoin: _vm.subjoin,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.subId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subId", $$v)
                              },
                              expression: "form.subId",
                            },
                          },
                          "v-select2",
                          _vm.subParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "暂停操作",
                        rules: [
                          {
                            required: true,
                            message: "请选择暂停操作",
                            trigger: "change",
                          },
                        ],
                        prop: "pauseType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.pauseTypeOps, width: _vm.width },
                        model: {
                          value: _vm.form.pauseType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pauseType", $$v)
                          },
                          expression: "form.pauseType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.timeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "暂停时间",
                            prop: "time",
                            rules: _vm.timeRules,
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              startTime: _vm.form.time.pauseFrom,
                              endTime: _vm.form.time.pauseTo,
                              type: "rangedate",
                            },
                            on: {
                              "update:startTime": function ($event) {
                                return _vm.$set(
                                  _vm.form.time,
                                  "pauseFrom",
                                  $event
                                )
                              },
                              "update:start-time": function ($event) {
                                return _vm.$set(
                                  _vm.form.time,
                                  "pauseFrom",
                                  $event
                                )
                              },
                              "update:endTime": function ($event) {
                                return _vm.$set(
                                  _vm.form.time,
                                  "pauseTo",
                                  $event
                                )
                              },
                              "update:end-time": function ($event) {
                                return _vm.$set(
                                  _vm.form.time,
                                  "pauseTo",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }